<template>
  <div>
    <div id="EmployeesAccount">
      <!--      头部文字-->
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("收藏商品") }}</div>
      </div>
      <!--      状态切换-->
      <div class="flex orderScreenContainer">
        <div class="orderScreenLeft flexAndCenter">
          <div v-for="(item,index) in addressSwitchover" :key="index"
               :class="switchoverIndex===index?'activeSwitchover':''"
               @click="switchoverIndex=index;form.goods_status=item.value;$forceUpdate;getData(form)">
            {{ item.label }}
          </div>
        </div>
        <!--      关键词搜索-->
        <div class="orderScreenBtnListContainer">
          <el-input :placeholder="$fanyi('请输入关键词')" v-model="form.keywords">
            <template slot="append">
              <div @click="getData(form)">{{ $fanyi('搜索') }}</div>
            </template>
          </el-input>
        </div>
      </div>
      <!--      标签名称-->
      <div class="flex tagMessageContainer" :style="tagList.length>0?'paddingBottom:14px':'paddingBottom:26px'">
        <div class="tagMessageLeftContainer flex">
          <div class="fontSize14 color333 tagNameText">{{ $fanyi('标签名称') }}：</div>
          <div class="tagListContainer">
            <div v-for="(item,index) in tagList" :key="index" class="tagContainer"
                 :style="form.tag_ids.lastIndexOf(item.id)!==-1?'border: 1px solid #B4272B;color:#B4272B':''"
                 @click="updateSearch(item)">
              <span v-if="tagInfo.id!==item.id">{{ item.tag_name }}</span>
              <el-input v-else v-model="item.tag_name" size="mini"
                        style="width: 100px" @input="$forceUpdate()" @blur.stop="updateTagName(item)"/>
              <img src="../../../../assets/redEditIcon.png" style="margin-left: 8px"
                   @click.stop="getEditTagInfo(item)"/>
              <div class="deleteTagContainer" @click.stop="deleteIcon(item)">x</div>
            </div>
          </div>
        </div>
        <!--      标签名搜索-->
        <div class="tagMessageRightContainer flexAndCenter">
          <div class="fontSize14 color333" style="width: 95px">{{ $fanyi('新增标签') }}：</div>
          <el-input :placeholder="$fanyi('请输入标签名')" v-model="tagValue">
            <template slot="append">
              <div @click="addTag">{{ $fanyi('新增') }}</div>
            </template>
          </el-input>
        </div>
      </div>
      <!--      操作栏-->
      <div class="goodsCollectListOperationContainer flexAndCenter">
        <div class="checkboxContainer flexAndCenter">
          <el-checkbox v-model="checked" @change="updateGoodsChecked">{{ $fanyi('全选') }}</el-checkbox>
          <img src="../../../../assets/icon/sort.png" alt="" @click="updateGoodsSort" class="sortIcon">
        </div>
        <div class="btnList flexAndCenter">
          <div @click="delGoods('','all')">{{ $fanyi('删除商品') }}</div>
        </div>
      </div>
      <!--      收藏商品列表-->
      <div class="goodsCollectListContainer">
        <div v-for="(item,index) in goodsList"
             :key="index" :class="item.goods_status==1?'newGoodsCollectContainer':'goodsCollectContainer'"
             :style="item.checked===true?'border: 1px solid #B4272B;':''">
          <div class="imgContainer"
               @mouseenter="goodsMouseenter(index)" @mouseleave="goodsMouseleave(index)">
            <!--      背景图片-->
            <img class="goodsImg" :src="item.image_url"
                 @click="$fun.toCommodityDetails(item.goods_id,item.shop_type)"/>
            <div class="goodsCollectOperationContainer flex">
              <!--      单选-->
              <input type="checkbox" style="margin-left: 0" v-model="item.checked" @change.stop="isAllSelectGoods"/>
              <div v-show="item.imgShowIcon===true">
                <!--      标签操作-->
                <el-popover trigger="hover">
                  <div class="tagSelectContainer" @mouseleave="goodsTagsMouseleave(item)">
                    <div class="tagSelectHeader" :style="tagList.length==0?'margin-bottom:0':''">{{
                        $fanyi('标签选择')
                      }}：
                    </div>
                    <div class="tagItemListContainer">
                      <div v-for="(tagItem,tagIndex) in tagList" :key="tagIndex" class="tagItem flexAndCenter"
                           @click="tagItem.checked=!tagItem.checked">
                        <input type="checkbox" style="margin-left: 0" v-model="tagItem.checked"/>
                        <div>{{ tagItem.tag_name }}</div>
                      </div>
                    </div>
                  </div>
                  <img src="../../../../assets/editTagIcon.png" slot="reference"
                       style="margin-right: 8px;width:20px" @mouseenter="goodsTagsMouseenter(item)">
                </el-popover>
                <!--      单个收藏商品删除-->
                <img src="../../../../assets/deleteGoods.png" @click.stop="delGoods(item,'noAll')"
                     style="width: 22px">
              </div>
            </div>
            <!--      图片搜索-->
            <div class="lookSimilarities" v-show="item.imgShowIcon===true" @click="imgSearchGoods(item.image_url)">
              <div class="flexAndCenter" @click="imgSearchGoods(item.image_url)">
                <i class="el-icon-search"></i>
                <div class="fontSize14" style="color: #fff">{{ $fanyi('查找相似') }}</div>
              </div>
            </div>
            <div v-if="item.goods_status==1" class="soldOutIcon">販売終了</div>
          </div>
          <div class="goodsTitle fontWeightBold u-line cursorPointer"
               style="height:16px" @click="shopDetailsPageJump(item)">
            {{ item.shopInfo.shopName != undefined ? item.shopInfo.shopName : '' }}
          </div>
          <div class="goodsTitle u-line-2">{{ item.title }}</div>
          <div class="goodsCollectFooterContainer flexAndCenter">
            <div>¥{{ item.price }}</div>
            <div>{{ item.shop_type }}</div>
          </div>
          <div style="margin:0 5px">
            <el-input v-model="item.remark" size="mini" @blur="updateGoodsRemark(item)"/>
          </div>
        </div>
      </div>
      <el-dialog
          :visible.sync="withdrawDepositErrorDialogVisible"
          width="372px"
          :close-on-click-modal="false"
          :close-on-press-escape="false">
        <div class="dialogHeader flexAndCenter">
          <div>!</div>
          <div>{{ $fanyi("提示") }}</div>
        </div>
        <div class="dialogFooter flexAndCenter">
          <div>{{ hintDialogVisibleText }}</div>
          <div @click="confirmDelete">{{ $fanyi("确定") }}</div>
        </div>
      </el-dialog>
      <div class="paginationContainer">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[15, 30, 45, 60]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {
        goods_status: undefined,
        order_by: 'desc',
        tag_ids: '',
        page: 1,
        pageSize: 15,
        keywords: ''
      },
      deleteGoodsType: 'noAll',
      hintDialogVisibleText: 'こちらの商品削除してよろしいですか。',
      withdrawDepositErrorDialogVisible: false,
      tagInfo: {},
      goodsInfo: {},
      checked: false,
      tagList: [],
      tagValue: '',
      addressSwitchover: [{
        label: this.$fanyi("全部商品") + '（0）',
        value: undefined
      }, {
        label: this.$fanyi("失效") + '（0）',
        value: '已下架'
      }],
      switchoverIndex: 0,
      total: 0,
      goodsList: [],
    };
  },
  computed: {},
  created() {
    this.getData(this.form);
    this.getTag();
    this.getGoodsTableNum();
  },
  methods: {
    // 获取数据
    getData(data) {
      this.$api.favoriteGoodsList(data).then((res) => {
        res.data.data.forEach((item) => {
          item.checked = false;
          item.imgShowIcon = false;
          this.$forceUpdate();
        })
        this.goodsList = res.data.data;
        this.total = res.data.total;
      })
    },
    getGoodsTableNum() {
      this.$api.goodsTableNum().then((res) => {
        res.data.forEach((item) => {
          if (item.name === "上架中") {
            this.addressSwitchover[0].label = this.$fanyi("全部商品") + `（${item.count}）`;
          } else if (item.name === "已下架") {
            this.addressSwitchover[1].label = this.$fanyi("失效") + `（${item.count}）`;
          }
        })
      })
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData(this.form)
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form)
    },
    updateGoodsRemark(item) {
      if (item.remark != '' && item.remark != null) {
        this.$api.favoriteGoodsUpdateRemark({
          id: item.id,
          remark: item.remark
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));

        }).catch(() => {
        });
      }
    },
    shopDetailsPageJump(item) {
      if (item.shopInfo != undefined && item.shopInfo.shopName != undefined) {
        let super_factory_status = undefined;
        let powerful_merchants_status = undefined;
        if (item.shopInfo.offerIdentities != undefined) {
          item.shopInfo.offerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
          item.shopInfo.offerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
        }
        this.$fun.routerToPage(`/shopGoods?shopId=${item.shopInfo.shop_id}&wangName=${item.shopInfo.wangName}&shopName=${item.shopInfo.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
      }
    },
    //更新标签的名称
    updateTagName(val) {
      if (val.tag_name !== '') {
        this.$api.tagNameEdit({
          tag_id: val.id,
          tag_name: val.tag_name
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.form.tag_ids = '';
          this.form.pageSize = 15;
          this.form.page = 1;
          this.tagInfo = {};
          this.getData(this.form);
          this.getGoodsTableNum();
          this.getTag();
        }).catch(() => {
        });
      }
    },
    //获取要编辑的tag信息
    getEditTagInfo(val) {
      this.tagInfo = this.$fun.deepClone(val);
      this.$forceUpdate();
    },
    //获取要筛选的标签
    updateSearch(val) {
      if (this.form.tag_ids === '') {
        this.form.tag_ids = val.id
      } else if (this.form.tag_ids !== '' && this.form.tag_ids.indexOf(val.id) === -1) {
        this.form.tag_ids += ',' + val.id
      } else if (this.form.tag_ids !== '' && this.form.tag_ids.indexOf(val.id) !== -1) {
        let index = this.form.tag_ids.indexOf(val.id);
        let target = '';
        if (index === 0) {
          target = val.id;
        } else {
          target = ',' + val.id;
        }
        this.form.tag_ids = this.form.tag_ids.replace(target, "");
      }
      this.getData(this.form);
    },
    //获取tag标签列表
    getTag() {
      this.$api.favoriteTagList({
        type: 1
      }).then((res) => {
        res.data.forEach((item) => {
          item.checked = false;
          item.id = item.id + ''
        })
        this.tagList = res.data;
      })
    },
    //更新列表排序状态
    updateGoodsSort() {
      this.form.order_by === 'asc' ? this.form.order_by = 'desc' : this.form.order_by = 'asc';
      this.getData(this.form);
      this.getTag();
      this.getGoodsTableNum();
    },
    //添加tag标签
    addTag() {
      if (this.tagValue !== '') {
        this.$api.favoriteTagAdd({
          type: 1,
          tag_name: this.tagValue
        }).then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.$message.success(res.msg);
          this.tagValue = '';
          this.getTag();
        })
      }
    },
    //商品详细鼠标移入
    goodsMouseenter(index) {
      this.goodsList[index].imgShowIcon = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(index) {
      this.goodsList[index].imgShowIcon = false;
      this.$forceUpdate();
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}？`)
      .then(_ => {
        done();
      })
      .catch(_ => {
      });
    },
    //查看tag鼠标移入
    goodsTagsMouseenter(item) {
      this.tagList.forEach((tagItem) => {
        tagItem.checked = false;
      })
      let tagIds = [];
      if (item.tag_ids !== '') {
        tagIds = item.tag_ids.split(',');
        this.tagList.forEach((tagItem) => {
          tagIds.forEach((tagIdsItem) => {
            if (tagItem.id == tagIdsItem) {
              tagItem.checked = true
            }
          })
        })
      }
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      this.$fun.routerToPage("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    //查看tag鼠标移出
    goodsTagsMouseleave(item) {
      let tagIds = '';
      this.tagList.forEach((tagItem) => {
        if (tagItem.checked === true) {
          tagIds += tagItem.id + ','
        }
      })
      tagIds = tagIds.substr(0, tagIds.length - 1);
      this.$api.favoriteGoodsToTags({
        id: item.id,
        tag_ids: tagIds
      }).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.getData(this.form);
      })
    },
    //确认删除以及删除后的处理
    confirmDelete() {
      if (this.hintDialogVisibleText === 'こちらの商品削除してよろしいですか。') {
        let arr = [];
        //是否是全部删除
        if (this.deleteGoodsType === 'noAll') {
          arr.push({shop_type: this.goodsInfo.shop_type, goods_id: this.goodsInfo.goods_id})
          this.$api.favoriteGoodsDelete({
            delete: arr
          }).then((res) => {
            if (res.code != 0) return this.$message.error(res.msg);
            this.hintDialogVisibleText = '削除完了しました！';
            this.withdrawDepositErrorDialogVisible = true;
            this.getData(this.form);
            this.getGoodsTableNum();
          })
        } else {
          this.goodsList.forEach((goodsItem) => {
            if (goodsItem.checked === true) {
              arr.push({shop_type: goodsItem.shop_type, goods_id: goodsItem.goods_id})
            }
          })
          if (arr.length > 0) {
            this.$api.favoriteGoodsDelete({
              delete: arr
            }).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.hintDialogVisibleText = '削除完了しました！';
              this.withdrawDepositErrorDialogVisible = true;
              this.getData(this.form);
              this.getGoodsTableNum();
            })
          }
        }
      } else {
        this.withdrawDepositErrorDialogVisible = false;
      }
    },
    //删除收藏商品
    delGoods(item, type) {
      if (type === 'noAll') {
        this.goodsInfo = item;
        this.deleteGoodsType = type;
        this.withdrawDepositErrorDialogVisible = true;
        this.hintDialogVisibleText = 'こちらの商品削除してよろしいですか。';
      }
      let arr = [];
      this.goodsList.forEach((goodsItem) => {
        if (goodsItem.checked === true) {
          arr.push(goodsItem);
        }
      })
      if (arr.length > 0) {
        this.deleteGoodsType = type;
        this.withdrawDepositErrorDialogVisible = true;
        this.hintDialogVisibleText = 'こちらの商品削除してよろしいですか。';
      }
    },
    //删除icon
    deleteIcon(item) {
      this.$confirm(
          '削除の確認',
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.favoriteTagDelete({
          tag_id: item.id
        }).then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.$message.success(res.msg);
          this.form.tag_ids = '';
          this.getTag();
          this.getData(this.form)
        })
      }).catch(() => {

      });
    },
    //更新全部商品选中状态
    updateGoodsChecked() {
      this.goodsList.forEach((item) => {
        item.checked = this.checked;
      })
    },
    //是否全部选中
    isAllSelectGoods() {
      let count = 0;
      this.goodsList.forEach((item, index) => {
        if (item.checked === true) {
          count++
        }
        if (index === this.goodsList.length - 1) {
          this.checked = count === this.goodsList.length;
        }
      })
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

/deep/ .el-dialog__header, /deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .el-dialog__headerbtn {
  top: 13px;
}

.dialogHeader {
  padding-left: 26px;
  height: 46px;
  border-bottom: 1px solid #DFDFDF;

  div:first-child {
    width: 20px;
    height: 20px;
    background: #B4272B;
    border-radius: 50%;
    color: #fff;
    margin-right: 6px;
    line-height: 20px;
    text-align: center;
  }

  div:last-child {
    color: #333333;
    font-weight: bold;
  }
}

.dialogCenter {
  .newTitle {
    margin: 20px 0 30px 20px;
    font-size: 20px;
    color: #B4272B;
    font-weight: bold;
  }

  .confirmMessageContainer {
    width: 280px;
    margin: 0 auto 30px;

    .label {
      text-align: right;
      width: 100px;
    }

    .balance {
      div:first-child {
        color: #B4272B;
        font-weight: bold;
        font-size: 16px;
        margin-right: 10px;
      }
    }

    .overflowText {
      //width: 135px;
      max-width: 135px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.dialogFooter {
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 20px;

  div:first-child {
    font-weight: bold;
    color: #333333;
  }

  div:last-child {
    margin-top: 39px;
    width: 120px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
  }
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.tagSelectContainer {

  .tagSelectHeader {
    color: #333333;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .tagItemListContainer {
    max-height: 300px;
    overflow-y: scroll;

    .tagItem {
      color: #999999;
      font-size: 14px;
      margin-bottom: 11px;
      cursor: pointer;

      div {
        margin-left: 4px;
      }
    }
  }
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .orderScreenContainer {
    margin: 26px 26px 0 26px;
    border-bottom: 1px solid #B4272B;
    justify-content: space-between;

    .orderScreenLeft {
      div {
        width: 130px;
        height: 40px;
        border-radius: 10px 10px 0 0;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid #B4272B;
        color: #B4272B;
        background: #fff;
        border-bottom: none;
      }

      .activeSwitchover {
        color: #fff;
        background: #B4272B;
      }
    }

    .orderScreenBtnListContainer {
      /deep/ .el-input__inner {
        width: 200px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px 0 0 6px;
      }

      /deep/ .el-input-group__append {
        color: #fff;
        background: #B4272B;
        height: 28px !important;
        border: none;
        line-height: 28px;
        cursor: pointer;
        padding: 0;
        text-align: center;
        width: 76px;
      }
    }
  }

  .tagMessageContainer {
    margin: 23px 26px 0;
    //padding-bottom: px;
    border-bottom: 1px solid #DFDFDF;
    justify-content: space-between;

    .tagNameText {
      min-width: 86px;
      width: 86px;
      margin-top: 4px;
    }

    .tagListContainer {
      display: flex;
      flex-wrap: wrap;
      width: 700px;

      .tagContainer {
        position: relative;
        padding: 7px 9px;
        color: #333;
        font-size: 12px;
        border-radius: 6px;
        background: #F0F0F0;
        margin-right: 22px;
        margin-bottom: 12px;
        cursor: pointer;
      }

      .deleteTagContainer {
        width: 12px;
        height: 12px;
        background: #B4272B;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        font-size: 12px;
        line-height: 8px;
        cursor: pointer;
        position: absolute;
        left: -5px;
        top: -5px;
      }

      img {
        position: absolute;
        right: -5px;
        top: -5px;
      }
    }

    .tagMessageRightContainer {
      max-height: 30px;

      /deep/ .el-input__inner {
        width: 132px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px 0 0 6px;
        margin-right: -25px;
      }

      /deep/ .el-input-group__append {
        color: #fff;
        background: #B4272B;
        height: 28px !important;
        border: none;
        line-height: 28px;
        cursor: pointer;
        padding: 0;
        text-align: center;
        width: 76px;
        //margin-top: 1px;
      }
    }
  }

  .goodsCollectListOperationContainer {
    justify-content: space-between;
    padding: 23px 0;
    margin: 0 25px;
    border-bottom: 1px solid #DFDFDF;

    .checkboxContainer {
      /deep/ .el-checkbox__input {
        margin-top: 16px;
      }

      .sortIcon {
        width: 20px;
        margin-left: 40px;
        cursor: pointer;
        margin-top: 5px;
      }
    }

    .btnList {
      div {
        width: 100px;
        height: 30px;
        background: #B4272B;
        border-radius: 6px;
        line-height: 30px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .goodsCollectListContainer {
    margin: 0 26px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .goodsCollectContainer, .newGoodsCollectContainer {
      width: 191px;
      height: 315px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 25px;
      margin-bottom: 26px;
      position: relative;

      .imgContainer {
        width: 191px;
        height: 191px;
        cursor: pointer;

        .goodsImg {
          width: 191px;
          height: 191px;
        }

        .goodsImg:hover {
          opacity: 0.3;
        }

        .soldOutIcon {
          position: absolute;
          top: 32%;
          left: 28%;
          width: 90px;
          height: 30px;
          background: #000000;
          border-radius: 15px;
          opacity: 0.5;
          font-size: 14px;
          color: #FFFFFF;
          text-align: center;
          line-height: 30px;
        }
      }

      input[type="checkbox"]:checked {
        background: #B4272B;
        border: 1px solid #B4272B;
      }

      .goodsCollectOperationContainer {
        position: absolute;
        top: 12px;
        left: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 172px;
        opacity: 1;

        .el-icon-folder, .el-icon-delete-solid {
          cursor: pointer;
        }

        ///deep/ .el-popover{
        //  display: block!important;
        //}
      }

      .lookSimilarities {
        position: absolute;
        width: 191px;
        height: 30px;
        background: #B4272B;
        bottom: 123px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 1;

        .el-icon-search {
          color: #fff;
          margin-right: 5px;
        }
      }

      .goodsTitle {
        color: #333333;
        font-size: 12px;
        margin: 4px 5px 6px 7px;
        height: 34px;
      }

      .goodsCollectFooterContainer {
        padding: 0 8px;
        justify-content: space-between;

        div:first-child {
          color: #B4272B;
          font-weight: bold;
          font-size: 18px;
        }

        div:last-child {
          color: #FF0000;
          font-weight: bold;
        }
      }
    }

    .newGoodsCollectContainer {
      .imgContainer {
        background: #000000;
        opacity: 0.6;
      }

      .goodsCollectFooterContainer div, .goodsTitle {
        color: #999999 !important;
      }
    }

    .goodsCollectContainer:nth-child(5n), .newGoodsCollectContainer {
      margin-right: 0;
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .addressListContainer {
    flex-wrap: wrap;
    margin-top: 26px;

    .activeAddressContainer {
      border: 1px solid #B4272B !important;
      background: #FCF2F2 !important;
    }

    .addressContainer, .activeAddressContainer {
      position: relative;
      width: 350px;
      height: 394px;
      background: #fff;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      padding: 10px 10px 0;
      margin-left: 26px;
      margin-bottom: 26px;

      .addressHeaderContainer {
        justify-content: space-between;
        width: 100%;

        input[type="checkbox"]:checked {
          background: #4A91E9;
          border: none;
          width: 16px;
          height: 16px;
        }

        div {
          width: 100px;
          height: 30px;
          background: #FAF2F2;
          border: 1px solid #B4272B;
          border-radius: 6px;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
          color: #B4272B;
        }
      }

      .addressName {
        color: #B4272B;
        font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
      }

      .addressMessageListContainer {
        margin-top: 20px;

        .addressMessageContainer {
          margin-bottom: 8px;
          margin-left: 20px;
          font-size: 14px;
          color: #000;
          text-align: left;

          div:first-child {
            width: 130px;
          }
        }
      }

      .btn {
        margin-top: 20px;
        width: 302px;
        height: 34px;
        background: #B4272B;
        border-radius: 8px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
        margin-left: 20px;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .addOrEditAddressContainer {
    /deep/ .el-dialog {
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;

      .el-dialog__header {
        padding: 0;

        .el-dialog__title {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          padding-bottom: 7px;
          border-bottom: 4px solid #B4272B;
        }

        .el-dialog__headerbtn {
          top: 30px;
          font-size: 19px;

          .el-dialog__close {
            color: #B4272b;
          }
        }
      }

      .el-dialog__body {
        padding: 0;
      }

      .el-dialog__footer {
        padding: 0;

        button {
          border-radius: 8px;
          height: 34px;
          width: 120px;
          line-height: 34px;
          padding: 0;
          font-size: 14px;
        }

        button:first-child {
          color: #B4272B;
          border: 1px solid #B4272B;
          margin-right: 26px;
        }

        .el-button + .el-button {
          margin-left: 0;
        }
      }
    }

    .checkboxContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 20px 0 30px;

      /deep/ .el-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 19px;

        .el-checkbox__inner {
          border-radius: 50%;
        }

        .el-checkbox__input {
          margin-bottom: -18px;
        }
      }
    }

    .formInput {
      /deep/ .el-input {
        width: 280px;

        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }

      /deep/ .el-textarea {
        width: 280px;
      }
    }
  }
}
</style>
